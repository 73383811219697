.sign-up-section {
  .sign-up-container {
    border-top-width: 1px;
    border-top-style: dashed;
    margin-top: 0.5rem;
    padding-top: 0.5rem;

    ul {
      padding-left: 1.5rem;
    }
  }
}
