.giftcards-row {
  .edit-button {
    height: 50px;
  }

  .deal-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    top: -10px;
    left: -8px;
  }

  .giftcard-options-container {
    .deal-icon {
      position: absolute;
      width: 28px;
      height: 28px;
      top: -6px;
      left: -6px;
    }

    .item-cost {
      line-height: 1;
    }

    .giftcard-container {
      position: relative;

      .giftcard-button {
        border-radius: 4px;
        font-size: 18px;
        height: 50px;
        min-width: 100px;
        padding: 8px;
        width: 100%;

        .check {
          display: inline-block;
          height: 18px;
          margin-right: 3px;
          width: 18px;
          vertical-align: text-top;
        }
      }

      &.variable-price {
        position: relative;

        &.multi {
          width: 100%;

          @media (min-width: $break-sm) {
            max-width: 200px;
          }

          @media (min-width: $break-sm) {
            max-width: 200px;
          }

          button {
            height: 50px;
            min-width: 100px;
          }
        }

        .giftcard-button.selected {
          text-align: left;
        }

        label {
          margin: 0;
        }

        input {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          font-size: 18px;
          font-weight: bold;
          padding: 0;
          width: 100%;
          display: flex;
          align-items: center;
        }
      }

      .context-aware-quantity-selector {
        .options-price {
          min-width: 75px;
        }
      }
    }

    .giftcard-selection-container {
      min-width: 100%;

      .quantity-selector {
        width: 140px;
      }
    }
  }
}
