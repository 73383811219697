.credit-card-payments-container {
  min-height: 70px;

  .amazon-pay-icon {
    width: 8.125rem;
    margin-right: 0.625rem;
    margin-top: 0.18rem;
  }

  .payment-icons-container {
    .payment-logos-text {
      white-space: nowrap;
    }

    .payment-icons {
      svg {
        width: 2rem;
      }
    }
  }

  .bordered-collapse {
    .vantiv-iframe {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      background-color: white;
      border-radius: 5px;
    }
  }
}
