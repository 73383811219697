.voucher-container {
  .voucher-selector {
    .action-button {
      min-width: 150px;
    }

    .form-label.disabled {
      opacity: 0.4;
    }

    input:disabled {
      opacity: 0.4;
    }

    .quantity-button svg {
      height: 40px;
      width: 40px;
    }

    .voucher-row {
      min-height: 60px;
      padding-top: 1rem;

      &:not(:last-child) {
        padding: 1rem 0;
      }

      .quantity {
        font-size: 1.333rem;
        font-weight: bold;
        line-height: 1;
      }

      .btn-sm {
        font-size: 10px;
        padding-top: 2px;
      }
    }
  }
}
