.quantity-button {
  background: none;
  border: none;
  height: 50px;
  padding: 2px; // allows the svg to overflow on iOS
  width: 50px;

  &.tiny {
    height: 35px;
    width: 35px;
  }

  &.small {
    height: 40px;
    width: 40px;
  }

  div {
    svg {
      overflow: visible;
    }
  }
}

.quantity-button.touch-start:enabled svg .btn-qty-b,
.quantity-button:not(.is-mobile):hover:enabled svg .btn-qty-b {
  transition: fill 0.1s ease-out;
}

.quantity-button.touch-start:enabled svg .btn-qty-d,
.quantity-button:not(.is-mobile):hover:enabled svg .btn-qty-d {
  transition: fill 0.1s ease-out;
}

.quantity-button-first {
  padding: 10px;

  &:hover {
    transition: 0.1s ease-out;
  }
}
