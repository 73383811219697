.voucher-ticket-type {
  padding: 15px 0;

  &:first-child {
    margin-top: 15px;
  }

  &:last-child {
    padding-bottom: 0;
  }

  p {
    margin: 0;
  }

  .quantity {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1;
  }

  .remove-button {
    font-size: 0.7rem;
  }
}
