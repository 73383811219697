.giftcard-payment {
  .action-button {
    min-width: 150px;
  }

  .breakdown {
    .btn-sm {
      font-size: 10px;
      padding-top: 2px;
    }
  }
}
