.delivery-option-container {
  height: 0;
  left: 0;
  margin: 0 0.5rem;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.75s ease-in-out;
  width: calc(100% - 1rem);
  z-index: 1050;

  &.show {
    height: 100svh;
    opacity: 1;
    transition: opacity 0.75s ease-in-out;
  }

  @media (min-width: $break-lg) {
    margin: 0;
    width: 66.667%;
  }

  .delivery-options {
    border-width: 2px;
    border-style: solid;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    bottom: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 1050;

    .top-buttons {
      button {
        text-transform: uppercase;

        svg {
          margin-right: 0.5rem;
          height: 1rem;

          .icon {
            transition: fill 0.1s ease-out;
          }
        }
      }
    }

    .delivery-options-list {
      max-height: 16rem;
      overflow: auto;
      padding: 0;

      .delivery-option {
        button {
          line-height: normal;

          svg {
            margin-right: 0.5rem;
            width: 2rem;
          }
        }

        &.selected {
          button {
            font-weight: bold;
          }
        }
      }

      .delivery-option:first-child {
        border-top: none;
      }
    }

    .delivery-comment {
      label {
        width: 100%;
      }

      input {
        border-radius: 8px;
        padding: 8px;
        width: 100%;
      }
    }
  }
}
