.local-date {
  font-size: 1.25rem;
}
.timer-container {
  width: 230px;
  margin: auto;

  .values {
    font-size: 2rem;
  }
}
