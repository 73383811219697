/* Styles for improved accessibility */

[data-whatintent='mouse'] a:focus,
[data-whatintent='mouse'] .btn:focus,
[data-whatintent='mouse'] .btn-link:focus,
[data-whatintent='mouse'] .nav-link:focus,
[data-whatintent='mouse'] button:focus,
[data-whatintent='mouse'] input:focus,
[data-whatintent='mouse'] input.form-control:focus,
[data-whatintent='mouse'] .invalid-feedback:focus,
[data-whatintent='mouse'] label.form-label svg:focus,
[data-whatintent='mouse'] .quantity-button:focus,
[data-whatintent='mouse'] select:focus,
[data-whatintent='mouse'] select.form-control:focus {
  box-shadow: none;
  outline: none;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
