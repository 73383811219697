.ticket-type {
  min-height: 83px;
  padding: 15px 0;

  p {
    margin: 2px 0;
  }

  .quantity {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
  }

  .deal-text-with-status {
    svg {
      height: 1rem;
      width: 1rem;
      margin-left: 0.25rem;
      margin-bottom: 0.2rem;
    }
  }

  .original-price {
    font-size: 0.9rem;
    text-decoration: line-through;
    margin-right: 5px;
  }
}
