.bordered-collapse {
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;

  .bordered-collapse-btn {
    border-radius: 6px;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    position: relative;

    &.no-pointer {
      cursor: default;
    }
  }

  &.hide-border {
    border: none;
    box-shadow: none;
  }

  &.highlight-border {
    border-width: 2px;
  }

  .bordered-collapse-border-top {
    border-top-style: dashed;
    border-top-width: 1px;
    padding-top: 0.5rem;
  }

  .close-button {
    font-size: 0.7rem;
    font-weight: normal;
    opacity: 0;
    position: absolute;
    right: 15px;
    text-transform: uppercase;
    top: 10px;
    transition: opacity 0.25s ease-in, visibility 0.25s ease-in;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}
