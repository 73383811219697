html {
  font-size: 16px;
}

body {
  font-weight: normal;
  line-height: normal;
}

a,
.a,
.a.btn-link {
  color: inherit;
  text-decoration: underline dotted;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-weight: bold;
  line-height: normal;
  margin: 0;
}

h1,
.h1 {
  font-size: 1.333rem;

  @media (min-width: $break-md) {
    font-size: 1.6rem;
  }
}

h2,
.h2 {
  font-size: 1.2rem;
}

h3,
.h3 {
  font-size: 1.1rem;
}

p,
.p {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 0.4rem;
  padding-bottom: 0 !important;
}

small,
.small {
  font-size: 0.625rem;
}

.tiny {
  font-size: 0.75rem;
}

.text-balance {
  text-wrap: balance;
}

.rich-text-tiny {
  p {
    font-size: 0.75rem;
  }
}

.dot-separator {
  font-weight: bold;
  margin: 0 6px;
}

b,
.b {
  font-weight: bold;
}

label.form-label {
  align-items: flex-end;
  display: inline-flex;
  font-size: 0.625rem;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
}

.spaced-letters {
  letter-spacing: 0.2rem;
  &::-webkit-input-placeholder {
    letter-spacing: normal;
  }
  &:-ms-input-placeholder {
    letter-spacing: normal;
  }
  &::-ms-input-placeholder {
    letter-spacing: normal;
  }
  &::-moz-placeholder {
    letter-spacing: normal;
  }
  &::placeholder {
    letter-spacing: normal;
  }
}

ul {
  line-height: 1.4;
}

li {
  margin-top: 0.25rem;
}
