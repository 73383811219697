.stored-card-payment {
  .stored-card-payment-item-rows {
    .stored-card-payment-item-row {
      margin-top: 0.5rem;
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;

      .card-details {
        svg {
          max-width: 60px;
        }
      }

      .spaced {
        letter-spacing: 0.15rem;
      }

      .remove-button {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
