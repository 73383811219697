.spreedly-payment {
  input,
  select {
    font-family: Arial, Helvetica, sans-serif;

    // this can't be customised for Spreedly, so we need to match their styles
    &.form-control.default-value {
      color: #777;
    }

    &::-webkit-input-placeholder {
      color: #777;
    }

    &:-ms-input-placeholder {
      color: #777;
    }

    &::-ms-input-placeholder {
      color: #777;
    }

    &::-moz-placeholder {
      color: #777;
    }

    &::placeholder {
      color: #777;
    }
  }
}
