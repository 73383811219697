.cart-summary {
  @media (min-width: $break-lg) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .cart-summary-row {
    padding: 1rem;

    &.no-border-no-padding {
      border-top: none;
      padding: 0;
    }

    &.prices {
      padding: 0.5rem 1rem 0.25rem;
    }

    .row,
    .cart-summary-row-flex {
      margin-top: 0.25rem;

      &:first-child {
        margin-top: 0;
      }
    }

    .concessions-item-list {
      .deal-icon {
        width: 28px;
        height: 28px;
        margin-right: 3px;
      }
    }

    .edit-col {
      min-width: 62px;

      .close-button {
        font-weight: bold;
        max-height: 22px;
        margin-right: 4px;
      }

      .icon {
        width: 18px;
        height: 20px;
      }
    }
  }

  .cart-items {
    padding: 0 1rem;

    &.hide-tax {
      padding-top: 1rem;
    }

    .cart-summary-row {
      padding: 1rem 0;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      & > :first-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  .cart-summary-row-flex {
    display: flex;
    justify-content: space-between;
  }

  .cart-summary-film-info {
    display: none;
    justify-content: flex-start;
    padding-bottom: 26px;

    @media (min-width: $break-lg) {
      display: flex;
    }
  }

  .cart-summary-giftcard-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;

    @media (min-width: $break-lg) {
      display: flex;
    }

    .image-container {
      min-width: 120px;
      max-width: 120px;
    }

    .text-container {
      padding: 1rem;
    }
  }

  .cart-summary-left {
    .poster {
      margin-right: 16px;

      img,
      svg {
        height: 120px;
        max-width: 80px;
      }
    }

    .still {
      margin-right: 16px;

      img,
      svg {
        height: 66px;
        max-width: 100px;
      }
    }
  }

  .cart-summary-right {
    text-align: left;

    .bottom {
      margin-top: 1rem;
    }
  }

  .no-poster {
    .a {
      fill: #9e9e9e;
    }
    .b {
      fill: #e5e5e4;
    }
  }
}
