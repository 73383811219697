.showtime-picker {
  .update-showtime-btn {
    text-transform: uppercase;

    .icon {
      height: 1.3rem;
      margin-left: 0.25rem;
      width: 1rem;
    }
  }

  .showtime-picker-date-row {
    .showtime-picker-date-container {
      max-width: 50px;

      .showtime-picker-date {
        position: -webkit-sticky;
        position: sticky;
        top: -1px;

        .date-top-container {
          .h2 {
            line-height: 1.2;
          }
        }
      }
    }

    &:last-child {
      .showtime-picker-sessions-container {
        .showtime-picker-sessions {
          height: calc(100vh - 130px);
        }
      }
    }

    .showtime-picker-sessions {
      .showtime-picker-attribute {
        svg {
          max-height: 40px;
          max-width: 40px;
        }

        &.large-icons {
          svg {
            max-height: 60px;
            max-width: 60px;
          }
        }

        &.wide-icons {
          svg {
            max-height: 60px;
            max-width: 100px;
            margin: 10px;
          }
        }
      }
    }

    .showtime-button-container {
      .showtime-button {
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        font-size: 0.933rem;
        font-weight: bold;
        line-height: 26px;
        margin: 0;
        padding: 2px 4px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        &:disabled {
          opacity: 0.45;
          pointer-events: all;
          -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
        }

        @media (max-width: calc($break-sm * 0.8)) {
          .showtime-hourminute {
            font-size: 0.8rem;
          }
        }
      }
    }

    .showtime-buttons-container {
      margin: 0 -0.25rem;
    }

    h2,
    .tiny {
      line-height: 1;
    }
  }
}
