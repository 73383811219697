.footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;

  .logo-container {
    margin: 20px auto;

    img {
      max-height: 60px;
      max-width: 200px;
    }
  }
}

.footer-copy {
  padding: 10px 0;

  p {
    margin-top: 0;
  }
}
