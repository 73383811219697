button[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=4);
  opacity: 0.4;
}

.sticky-button {
  bottom: 0;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 1030;

  @media (min-width: $break-md) {
    background: none;
    bottom: auto;
    position: -webkit-relative;
    position: relative;
  }
}

.sticky-button > div {
  border-top-width: 1px;
  border-top-style: solid;

  @media (min-width: $break-md) {
    border-top: none;
  }
}

.sticky-button-mobile-desktop {
  bottom: 0;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 1030;
}

.action-button-container {
  text-align: center;
}

.action-button {
  letter-spacing: 0.25px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
  width: 100%;

  svg {
    transition: all 0.1s ease-in-out;
  }

  &.btn-modal {
    min-width: 50%;
    width: unset;
  }

  &.btn-large {
    font-size: 0.875rem;
    padding: 8px 12px;
    text-align: left;
  }
}

.cart-summary-button {
  padding: 8px 12px;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.spinner-icon {
  margin-right: 0.5rem;
}

.icon-arrow {
  height: 26px;
  width: 26px;

  svg {
    height: 26px;
    width: 26px;
  }

  &-reverse {
    transform: rotate(180deg);
    height: 26px;
    width: 26px;
  }
}

.checkbox-button {
  .selectable-button-svg {
    height: 26px;
    width: 26px;
  }
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;

  &.back {
    & .icon-arrow svg {
      transform: rotate(180deg);
    }
  }
}

.btn-edit.btn-link {
  border-radius: 6px;
  padding: 0.5rem;
  width: 100%;
}

.btn:focus-visible,
.btn:first-child:active:focus-visible,
.btn.show:focus-visible,
.nav-link:focus-visible {
  box-shadow: none;
}
