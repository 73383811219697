.banner {
  height: '42px';
  &.extra-margin {
    margin-top: 0.25rem;
  }

  svg {
    height: 1rem;
    width: 1rem;
    margin-left: 0.25rem;
    margin-bottom: 0.2rem;
  }
}
