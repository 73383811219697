.loading-mask {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 5000;

  .spinner {
    background-color: rgba(255, 255, 255, 0.55);
    height: 70px;
    left: calc(50% - 35px);
    position: fixed;
    top: calc(50% - 35px);
    width: 70px;
    z-index: 5001;
  }
}
