#AmazonPayButton {
  img {
    width: 100%;
  }
}

#AmazonWalletWidget {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1; /* Set the aspect ratio of the iframe */
  overflow: hidden;
  margin-bottom: 1.25rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none !important;
  }
}
