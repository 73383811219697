input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.form-control {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-shadow: none;
  height: 40px;

  &.is-valid {
    background-image: url(../../svgs/tick.svg);
  }

  &.is-invalid {
    background-image: url(../../svgs/warning.svg);
  }
}

textarea.form-control {
  height: 60px;
  resize: none;
}

.invalid-feedback {
  font-size: 0.775rem;
  padding-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-position: right calc(0.375em + 0.1875rem) center;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

select.form-control.is-valid,
select.form-control.is-invalid {
  background-position: right calc(0.375em + 1.5rem) center;
}

.icon-info {
  height: 26px;
  width: 26px;
}

.svg-info {
  height: 1rem;
  margin: 0 5px;
  margin-bottom: 2px;
}

.tooltip {
  z-index: 1020;
}
