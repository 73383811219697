.header {
  .header-container {
    min-height: 50px;

    .logo-container {
      margin: 0 auto;

      img {
        max-height: 50px;
        max-width: 200px;
      }
    }

    .header-button-container {
      position: relative;

      .header-button {
        background-color: transparent;
        border: none;
        height: 22px;
        text-transform: uppercase;

        .header-button-icon {
          svg {
            height: 22px;
            width: auto;
          }
        }
      }
    }
  }
}
