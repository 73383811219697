.mini-categories {
  &.sticky-top {
    top: -1px;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }

  .embla-button {
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0px;
    z-index: 100;
    border: none;

    &[disabled] {
      display: none;
    }
  }

  .embla-viewport {
    overflow: hidden;
    width: fit-content;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .embla-container {
    display: flex;
  }

  .embla-slide {
    font-size: 0.75rem;
    font-weight: normal;
    border-radius: 5px;
    display: inline-block;
    flex: 0 0 fit-content;
    min-width: fit-content;
    width: fit-content;
    white-space: nowrap;
    user-select: none;
    margin-left: 0;

    &:focus {
      outline-offset: 3px;
      text-decoration: none;
    }
  }
}
