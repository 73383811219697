.giftcards {
  .giftcard-rows-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .accordion-collapse {
      .giftcards-row {
        border-bottom: none;
        border-top: none;
      }
    }

    &.summary-rows {
      .giftcards-row {
        border-top: none;
        .image-container {
          img {
            max-width: 80px;
            width: 100%;
          }

          @media (min-width: $break-md) {
            img {
              max-width: 104px;
            }
          }
        }

        .summary-item {
          @media (min-width: $break-md) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .add-giftcard-button-row {
    .add-giftcard-button {
      border: none;
      line-height: 2rem;
      width: 100%;

      .icon-arrow {
        width: 20px;
      }
    }
  }

  .recipient-details {
    .detail {
      div {
        margin-top: 0.25rem;
      }
    }
  }
}

.variable-cost-container {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  height: 45px;
  max-width: 200px;

  .variable-cost-input {
    border-width: 0;
    font-size: 18px;
    font-weight: bold;
    min-width: 30px;
    padding: 0;
  }
}

.options-selector-container {
  .option-button {
    align-items: center;
    border-radius: 4px;
    height: 45px;
    min-width: 150px;

    &.disabled {
      opacity: 0.5;
    }

    .check {
      display: inline-block;
      margin-right: 3px;
      width: 18px;
      vertical-align: text-top;
    }
  }
}
