.customer-wrapper {
  .collapse-btn-wrapper {
    position: relative;

    .collapse-btn-divider {
      border-top-style: solid;
      border-top-width: 1px;
      position: absolute;
      top: 50%;
    }

    .collapse-btn.btn-link {
      border-radius: 20px;
      border-style: solid;
      border-width: 1px;
      padding: 0.25rem 3rem;
      position: relative;
      text-transform: uppercase;
      z-index: 1;
    }
  }
}
