.layout {
  .layout-top {
    flex: 1 0 auto;
  }

  .view {
    .select-heading {
      line-height: 1.8rem;
    }
  }

  .sidebar {
    padding-bottom: 4rem;
    position: relative;
  }

  .contained {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (min-width: $break-md) {
      max-width: 450px;
    }

    img {
      max-width: 100%;
    }
  }

  .bordered {
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    padding: 1rem;
  }
  s .concession.concession-modal {
    border-bottom: none;
  }
}

.all-selected-check {
  display: inline-block;
  height: 1.8rem;
  margin-right: 6px;
  width: 1.8rem;
  vertical-align: text-top;
}

.layout-modal {
  &.error-modal {
    z-index: 1085;
  }

  .modal-content {
    border: none;
    border-radius: 6px;
    margin: 0 auto;
    max-width: 600px;
    padding: 0 1rem;
  }

  .modal-header {
    border: none;
    padding: 1rem;
  }

  .modal-title {
    border: none;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .modal-body {
    padding: 1rem;

    p:first-child {
      margin-top: 0;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
    padding: 1rem;

    .row {
      width: 100%;
    }
  }

  &.small-buttons {
    .modal-footer {
      .row {
        width: auto;

        .action-button {
          min-width: 280px;
        }
      }
    }
  }
}

.info-container {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;

  p {
    margin: 0;
  }
}

.warning-container {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;

  p {
    margin: 0;
  }
}

.layout-mask {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.5s ease-in-out;
  width: 100vw;
  z-index: 1050;
}
