.gift-card-image-container {
  min-height: 60px;
  max-height: 60px;
  min-width: 100px;
  max-width: 100px;

  .fallback {
    height: 100%;
    width: 100%;

    .a {
      fill: #e0e0e0;
    }
    .b {
      fill: #000000;
    }
    .c {
      fill: #000000;
    }
  }
}
