.total-wrapper {
  align-items: center;
  font-size: 0.75rem;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  top: 0;
  z-index: 1040;
  height: 44px;

  @media (min-width: $break-lg) {
    font-size: 1.333rem;
    height: 52px;
    text-align: center;
  }

  .cart-button {
    padding: 0.5rem 0.75rem;

    svg {
      height: 20px;
      width: auto;
    }
  }

  .total-text {
    display: inline-block;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
  }

  .countdown-wrapper {
    align-self: center;
    display: flex;
    margin: 0.15rem 0;
    text-align: center;
    position: absolute;
    right: 12px;

    .countdown-container {
      border-radius: 5px;
      display: inline-block;
      font-size: 0.5rem;
      padding: 0.25rem 0.5rem;
      position: relative;

      @media (min-width: $break-lg) {
        font-size: 0.7rem;
      }
    }

    .countdown-time {
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.1;

      @media (min-width: $break-lg) {
        font-size: 1rem;
      }
    }
  }
}
