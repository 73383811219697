.launcher {
  background-color: #fff;
  color: #000;
  left: 0;
  min-height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  .version-container {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
  }
}
