.custom-loyalty-wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}

input.custom-loyalty-input {
  border-left: 0px;
  border-radius: 0 4px 4px 0;
}

input.custom-loyalty-input:focus {
  border-left: 0px;
}

.currency-symbol {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px 0 0 4px;
  box-shadow: none;
}
