.amount-selector {
  .amount-selector-buttons {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    button {
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      min-height: 3.5rem;
      padding: 8px;
      width: 100%;

      .check {
        display: inline-block;
        margin-right: 4px;
        width: 26px;
        vertical-align: text-top;
      }

      .small {
        margin-top: 0;
      }
    }

    .amount-selector-custom-wrapper {
      position: relative;
      width: 100%;

      button {
        min-width: 200px;

        label {
          cursor: pointer;
          margin: 0;
        }

        input {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          font-size: 18px;
          font-weight: bold;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
