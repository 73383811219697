#payrixPayment {
  label.form-label {
    margin-left: 0.75rem;
  }

  .form-control {
    &.payfield {
      height: 80px;
      border: none;

      input {
        background-color: white;
      }

      &.is-valid {
        background-position: right calc(0.375em + 0.1875rem) top 18px;
      }

      &.is-invalid {
        background-position: right calc(0.375em + 0.1875rem) top 18px;
      }
    }
  }

  #payrixZipcodeDiv {
    input {
      box-sizing: unset;
      display: block;
      width: -webkit-fill-available;
      height: 30px;
      padding: 5px;
      margin-bottom: 5px;
      border-radius: 0px;
      font-family: Arial;
      font-size: 13.3333px;
      line-height: normal;
      border-width: 1px;

      &:-moz-placeholder {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #666666;
      }

      &:-ms-input-placeholder {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #666666;
      }

      &::-webkit-input-placeholder {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #666666;
      }

      &::-moz-placeholder {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #666666;
      }

      &::placeholder {
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #666666;
      }
    }

    span {
      color: red;
      font-family: sans-serif;
      font-size: 14px;
      display: block;
      height: 40px;
    }

    .form-control {
      &.zipcode-is-invalid {
        border-color: red;
      }

      &.zipcode-is-valid {
        border-color: green;
      }
    }
  }

  #apple-pay-container {
    width: 150px;
    height: 30px;

    apple-pay-button {
      display: block;
      --apple-pay-button-width: 150px;
      --apple-pay-button-height: 30px;
      --apple-pay-button-border-radius: 3px;
      --apple-pay-button-padding: 0px 0px;
      --apple-pay-button-box-sizing: border-box;
    }
  }
}
