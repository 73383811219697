.showtime-hero {
  border-radius: 8px;
  overflow: hidden;

  &.showtime-picker-view {
    height: calc(100vh - 2rem);
    padding-bottom: 100px;
    position: fixed;
    top: 0;
    width: calc(100% - 2rem);
    z-index: 1070;

    @media (min-width: $break-md) {
      max-height: 800px;
      max-width: 450px;
    }
  }

  .showtime-hero-top {
    overflow: hidden;
    position: relative;

    .backdrop {
      background-color: #000;
      background-position: center -35px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 100;
    }

    .blur {
      -webkit-filter: blur(4.5px);
      filter: blur(4.5px);
    }

    .caption {
      background-color: rgba(0, 0, 0, 0.55);
      color: #fff;
      height: 100%;
      padding: 12px 10px 20px;
      position: relative;
      width: 100%;
      z-index: 200;

      h2 {
        color: #fff;
        margin: 0.5rem 3rem 0;
      }

      p {
        line-height: 1.4;
        margin-top: 0.25rem;

        &:first-child {
          margin-top: 0;
        }

        &.extra-margin {
          margin-top: 0.5rem;
        }
      }

      .close-showtime-picker-button {
        position: absolute;
        right: 10px;
        top: 10px;

        .icon {
          height: 2rem;
          width: 2rem;

          .a {
            fill: #fff;
          }
        }
      }

      .showtime-hero-attribute {
        svg {
          max-height: 30px;
          max-width: 30px;
        }

        &.large-icons {
          svg {
            max-height: 50px;
            max-width: 50px;
          }
        }

        &.wide-icons {
          svg {
            max-height: 50px;
            max-width: 100px;
            margin: 10px;
          }
        }
      }
    }
  }

  .showtime-hero-session-picker {
    border-radius: 0 0 8px 8px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 200;
  }

  .cart-summary {
    .cart-summary-row:first-child {
      border-top: none;
    }
  }
}

.showtime-picker-mask {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.5s ease-in-out;
  width: 100vw;
  z-index: 1060;
}
