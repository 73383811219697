.concession {
  .image-container {
    img {
      @media (max-width: 767px) {
        height: 70px;
      }

      height: min-content;
      max-height: 100px;
      width: 100%;
      max-width: 100px;
      object-fit: scale-down;
      object-position: 50%;
    }
  }

  .deal-icon {
    position: absolute;
    width: 28px;
    height: 28px;
    top: -10px;
    left: -8px;
  }

  .options-selector-container {
    .options-selector {
      .quantity-button-container {
        width: 50px;
      }

      .quantity-col {
        min-width: 54px;
        font-size: 18px;
        max-width: 150px;
      }

      &:hover {
        cursor: pointer;
      }

      &.bordered {
        padding: 10px;
      }
    }

    @media (max-width: 767px) {
      &.button {
        width: 100%;

        .options-selector {
          width: 100%;
        }

        margin-left: 0;
      }
    }
  }

  .modifier-groups {
    .multi-option-container {
      &.bordered {
        padding: 0;
      }

      .multi-option {
        position: relative;

        .heading-btn {
          cursor: pointer;
          padding: 0.625rem;
          width: 100%;
          text-transform: uppercase;

          & .accordion-icon {
            font-size: 2rem;
            line-height: 1rem;
            width: 18px;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            text-align: right;
          }
        }

        .close-icon {
          position: absolute;
          right: 6px;
          top: 3px;
          width: 18px;

          &:hover {
            cursor: pointer;
          }
        }

        .validated {
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
          height: 18px;
          left: 10px;
          position: absolute;
          top: 12px;
          width: 18px;
        }
      }

      .modifier-item {
        .quantity-col {
          width: 30px;
        }

        button {
          line-height: normal;

          svg {
            width: 2rem;
          }

          .unsel {
            .b {
              transition: fill 0.1s ease-out;
            }
          }
        }

        &.selected {
          button {
            font-weight: bold;
          }
        }

        &:only-child {
          margin-top: 0;
        }

        .label:hover {
          cursor: pointer;
        }

        &-selected {
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;

          &:hover {
            cursor: pointer;
          }

          & button svg {
            width: 1.5rem;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
          }
        }
      }
    }
  }
}

.concessions-booking-info {
  .booking-info-left .still,
  .booking-info-left .poster {
    margin-right: 16px;
  }

  .booking-info-left .still img,
  .booking-info-left .still svg {
    height: 66px;
    max-width: 100px;
  }

  .booking-info-left .poster img,
  .booking-info-left .poster svg {
    height: 120px;
    max-width: 80px;
  }
}

.options-selector {
  &-bordered {
    border-radius: 0 0 6px 6px;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
  }
}

.required-selection {
  &.valid {
    & svg {
      height: 17px;
      margin-right: 0.5rem;
    }
  }
}

.modifier-cost {
  margin-right: 1rem;
}
