.loyalty-promo {
  .hide-button {
    margin-top: 4px;
    text-transform: uppercase;
    transition: color 0.1s ease-out;

    .icon {
      height: 1.3rem;
      margin-left: 0.25rem;
      width: 1rem;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
