.dropdown-toggle::after {
  display: none;
}

.extra-menu-dropdown {
  z-index: 1050;
  min-width: 300px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 0;
  overflow: hidden;

  .dropdown-header {
    border-bottom: none;
    font-weight: bold;
    overflow: hidden;
    text-align: center;
    word-wrap: normal;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }
  }

  .dropdown-item-text {
    padding: 0;
  }

  .multi-language {
    .multi-lang-option {
      border-left: none;
      border-right: none;
      border-bottom: none;

      &.selected {
        font-weight: bold;
      }
    }

    .multi-lang-option:first-child {
      border-top: none;
    }
  }
}
