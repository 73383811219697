.payment {
  .giftcard-upsell-summary {
    .giftcards-row {
      border: none;

      .image-container {
        img {
          max-height: 50px;
          max-width: 80px;
        }

        @media (min-width: $break-md) {
          img {
            max-height: 100px;
            max-width: 160px;
          }
        }
      }

      .summary-item {
        @media (min-width: $break-md) {
          font-size: 1.2rem;
        }
      }

      .recipient-details {
        .detail-line {
          margin-top: 0.25rem;
        }
      }

      .collapse-btn {
        border: none;
        padding: 0.25rem 0;
        position: relative;
        text-transform: uppercase;
        z-index: 1;

        svg {
          height: 1.25rem;
          width: 1.25rem;
          margin-left: 0.25rem;
        }
      }
    }
  }

  .payment-icons {
    svg {
      margin: 0.25rem;
      opacity: 0.25;
      height: 2rem;
      width: auto;

      &.payment-icon-active {
        opacity: 1;
      }
    }
  }

  .tabbed-container.bordered {
    border: none;
    padding: 0;
  }

  .full-payment-wrapper {
    .payment-icons {
      .giftcard {
        margin: 0 auto;
        width: 40px;
      }
    }

    .tab-content {
      border-radius: 0 0 6px 6px;
      border-top: none;
    }

    .nav {
      flex-wrap: unset;
    }

    .nav-tabs {
      border-bottom: none;
      border-radius: 6px 6px 0 0;

      a {
        font-weight: bold;
        height: 100%;
        text-decoration: none;
      }

      .nav-link {
        border-radius: 6px 6px 0 0;
      }

      & .nav-link.active,
      & .nav-item.show .nav-link {
        cursor: default;
        font-weight: bold;
      }
    }

    .nav-item {
      max-width: 50%;
    }
  }
}

.terms-and-conditions {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 6px;

  button {
    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
