$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      0.667rem,
    ),
    2: (
      1rem,
    ),
    3: (
      1.333rem,
    ),
    4: (
      2rem,
    ),
    5: (
      2.667rem,
    ),
    6: (
      3.333rem,
    ),
  ),
  $spacers
);
$btn-border-radius: 4px;
$btn-disabled-opacity: 0.4;
$modal-backdrop-opacity: 0.75;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7.87'><path class='a' d='M.87,0H13.13a.87.87,0,0,1,.61,1.49L7.62,7.61a.88.88,0,0,1-1.24,0L.26,1.49A.87.87,0,0,1,.87,0Z'/></svg>");
