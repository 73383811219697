// this is needed to keep the footer at the bottom of the page if not enough content
html,
body,
#root,
.app {
  height: 100%;
}

html.no-scroll {
  height: 100vh;
}

body.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

// breakpoints
$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;
$break-xxl: 1600px;

// hide recaptcha badge on mobile
.grecaptcha-badge {
  z-index: 1050;

  @media (max-width: 767px) {
    visibility: hidden;
  }
}

.error-modal-backdrop {
  z-index: 1080;
}
