.season-pass-cart-summary {
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.season-pass-img {
  width: 100%;
  border-radius: 8px;
}

.season-pass-movie-img {
  min-width: 64px;
  max-width: 64px;
  height: 'auto';
}

.session-button {
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;

  &:disabled {
    opacity: 0.45;
  }

  .radio {
    display: inline-block;
    margin-right: 3px;
    width: 18px;
    vertical-align: text-top;
  }
}

.season-pass-select-showtimes-button {
  width: 100%;
  border-top: none;
}

.offcanvas.offcanvas-end {
  width: 1200px;
}

.session-button-container {
  width: 100%;
}

.offcanvas-title {
  .all-selected {
    color: inherit;

    .b,
    .c {
      fill: inherit;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: inherit;
  }

  svg {
    width: 1.4rem;
  }
}
