.password-container {
  position: relative;

  .form-control.is-valid,
  .form-control.is-invalid {
    background-image: none;
  }

  .btn-show-hide {
    background: none;
    border: none;
    margin: 0;
    right: 0.75rem;
    padding: 0;
    position: absolute;
    top: 5px;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}

.go-back-to-website-button,
.go-back-to-website-button:hover {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0.4rem;
  text-transform: uppercase;
  width: 100%;

  .arrow {
    height: 1.3rem;
    margin-right: 0.5rem;
    transform: rotate(180deg);
    width: 1.3rem;
  }

  &:hover {
    text-decoration: underline;
  }
}
